<template>
  <div class="common-bg-member">
    <div class="middle common-padding-120">
      <div class="form-bg">
        <h1>
          註冊
        </h1>
        <div class="form-1">
          <div class="form-item">
            <p>先生/小姐/女士（Mr/Miss/Mrs）<span>*</span></p>
            <el-select v-model="sex" placeholder="請選擇" class="m-select">
              <el-option :value="0" label="Mr">Mr</el-option>
              <el-option :value="1" label="Miss">Miss</el-option>
              <el-option :value="2" label="Mrs">Mrs</el-option>
            </el-select>
          </div>
          <div class="form-item">
            <p>姓名<span>*</span></p>
            <el-input v-model="name" class="form-item-input" />
          </div>
        </div>
        <div class="form-1">
          <div class="form-item">
            <p>電郵<span>*</span></p>
            <el-input v-model="email" class="form-item-input" />
          </div>
           <!--  验证码     -->
          <div class="form-item">
            <p>驗證碼<span>*</span></p>
            <div class="v-code">
              <div>
                <div class="sCode">
                  <span class="countdown" :class="{ 'disabled': isCountdownActive }" @click="sendCode">{{ countdownText }}</span>
                </div>
              </div>
              <el-input v-model="vCode"  class="form-item-input"/>
            </div>
          </div>
        </div>

        <div class="form-1">
          <div class="form-item">
            <p>設置密碼<span>*</span></p>
            <el-input v-model="password" type="password" class="form-item-input" />
          </div>
          <div class="form-item">
            <p>電話號碼<span>*</span></p>
            <div class="v-code">
              <AreaCode v-model:areaCode="areaCode" @update:areaCodeID="handleAreaCodeIDUpdate" />
              <el-input v-model="tel"  class="form-item-input tel-input" />
            </div>
          </div>
        </div>

        <div class="form-1 form-2">
          <p>介紹人編號（選填）</p>
          <el-input v-model="invitationCode"  class="form-item-input" />
        </div>

        <div class="level">
          <div class="level-title"><b>選擇會員等級</b></div>
          <div class="level-list">
            <ul>
              <li v-for="item in list" :key="item.id" :class="{ 'active': selectedId === item.id }" @click="selectItem(item.id)">
                <h3>{{ item.title }}</h3>
                <h1>${{ item.price }}/年</h1>
                <div class="level-info" v-for="des in  item.description" :key="des">
                  <span>
                    <i class="iconfont icon-duigouxiao"></i>
                  </span>
                  <p>{{ des }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="send">
          <button @click="clickReg">立即註冊</button>
        </div>
      </div>
    </div>

  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

  <!-- 大彈窗 -->
  <div>
    <Dialog
        :title="dialogTitle"
        :description="dialogDescription"
        v-model:visible="dialogVisible"
        @update:visible="handleDialogVisibleChange"
    />
  </div>

</template>

<script>

import AreaCode from "@/components/AreaCode";
import Dialog from "@/components/Dialog";
import {getMemberLevel, sendCode, register} from "@/network/main";
export default {
  name: "register",
  components:{
    AreaCode,
    Dialog
  },
  data() {
    return {
      sex:0,
      name:'',
      vCode:'',
      email:'',
      areaCode:'+852',
      password:'',
      tel:'',
      invitationCode:'',
      list:'',
      selectedId: null,
      areaCodeID: null,
      isPopupVisible: false,
      popupText: '',
      countdownTimer: null,
      countdownSeconds: 60,
      isCountdownActive: false,
      dialogTitle: '註冊成功',
      dialogDescription: '您已註冊成功，請在管理員審核通過後登錄！',
      dialogVisible: false
    };
  },
  computed: {
    countdownText() {
      return this.isCountdownActive ? `${this.countdownSeconds}s` : '發送驗證碼';
    }
  },
  created() {
    this.getMemberLevel()
  },
  mounted() {
    // 在页面加载时判断链接中是否带有邀请码
    const invitationCode = this.$route.params.invite_code
    this.invitationCode = invitationCode
    if (invitationCode) {
      // 如果链接中有邀请码，则将邀请码赋值给输入框
      this.invitationCode = invitationCode
    }
  },
  methods: {
    //拿到區號ID
    handleAreaCodeIDUpdate(newValue) {
      this.areaCodeID = newValue;
      console.log('areaCodeID:', this.areaCodeID);
    },
    //會員種類
    getMemberLevel(){
      getMemberLevel().then((res)=>{
         this.list= res.data.data;
         this.selectedId = this.list[0].id;
      }).catch(err=> {
        console.log(err)
      })
    },
    //獲取會員的當前狀態
    selectItem(id) {
      this.selectedId = id;
    },
    //发送验证码
    sendCode() {
      this.startCountdown()
      const formData = new FormData();
      formData.append('email', this.email);
      sendCode(formData).then((res)=>{
       if(res.data.code===200){
            this.isPopupVisible = true
            this.popupText="驗證碼已發送！"
            setTimeout(() => {
            this.isPopupVisible = false;
            }, 2000);
       }else {
         this.isPopupVisible = true;
         this.popupText = res.data.message;
         setTimeout(() => {
           this.isPopupVisible = false;
         }, 2000);
       }
      }).catch(err=> {
        console.log(err)
       })
    },
    //倒計時
    startCountdown() {
      this.isCountdownActive = true;
      this.countdownTimer = setInterval(() => {
        this.countdownSeconds--;
        if (this.countdownSeconds === 0) {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownTimer);
      this.countdownSeconds = 60;
      this.isCountdownActive = false;
    },
    //註冊
    clickReg(){
      const formData = new FormData();
      formData.append('sex', this.sex);
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('code', this.vCode);
      formData.append('password', this.password);
      formData.append('phone', this.tel);
      formData.append('invitation_code', this.invitationCode);
      formData.append('member_id', this.selectedId);
      formData.append('area_code_id', this.areaCodeID);
      //提交數據
      register(formData).then((res)=>{
        if(res.data.code===200){
          this.name = "";
          this.email = "";
          this.vCode = "";
          this.password = "";
          this.tel = "";
          this.invitationCode = "";
          this.selectedId = 1;
          this.dialogVisible=true;
          this.$emit('update:visible', true); // 手动触发 @update:visible 事件
        }else {
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    //小弹窗点击确认后刷新页面
    handleDialogVisibleChange(visible) {
      this.dialogVisible = visible;
      if (!visible) {
        this.$router.push('/success');
      }
    },
  }
}
</script>

<style scoped>
.form-bg h1{
  text-align: center;
  padding-bottom: 30px;
}
.form-1{
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.form-item{
  width: 48%;
}
.form-item p{
  padding-bottom: 5px;
}
.form-item p span, .form-2 p span{
  color: #ff0000;
  padding-left: 5px;
}
.form-item-input{
  height: 48px;
  font-size: 20px;
  border-radius: 4px;
  background: #F1F1F1;
  box-shadow: none!important;
}
.form-2{
  display: block;
}

.form-2 .form-item-input{
   margin-top: 10px;
}
.choose div{
  width: 25%;
  margin-top: 10px;
}

.send{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.send button{
  width: 100%;
  padding: 15px 50px;
  line-height: inherit;
  height: inherit;
  background: var(--background);
  border: none;
  color: #fff;
  font-size:18px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  white-space: pre-line;
  line-height: 2;
}

.disabled {
  cursor: not-allowed;
  color: #999;
}

@media only screen and (min-width:0) and (max-width:767px){

  .form-bg{
    padding: 0;
  }
  .form-bg h1{
    font-size: 24px;
  }
  .form-1{
    flex-wrap: wrap;
    padding-top: 0;
  }
  .form-item{
    width: 100%;
    margin-top: 20px;
  }
  .form-2{
    margin-top: 20px;
  }
  .choose div{
    width:50%;
  }
}
</style>