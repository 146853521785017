<template>
  <div class="areaCode">
    <el-select v-model="selectedAreaCode" placeholder="請選擇" class="m-select">
      <el-option v-for="item in areaCodeOptions" :key="item.id" :value="item.code">
        {{ item.code }}
      </el-option>
    </el-select>
  </div>
</template>

<script>

import {getAreaCode} from "@/network/main";

export default {
  name: 'AreaCode',
  data() {
    return {
      selectedAreaCode: null,
      areaCodeOptions: [],
      areaCodeID: null,
    }
  },
  created() {
    this.getAreaCode()
  },
  methods: {
    getAreaCodeID() {
      return this.areaCodeID;
    },

    getAreaCode() {
      getAreaCode().then(res => {
        this.areaCodeOptions = res.data.data;
        // 设置 selectedAreaCode 为第一个 areaCodeOptions 的 code
        this.selectedAreaCode = this.areaCodeOptions.length > 0 ? this.areaCodeOptions[0].code : null;
        // 找到选中的 area code 对应的 ID
        const selectedOption = this.areaCodeOptions.find(
            item => item.code === this.selectedAreaCode
        );
        this.areaCodeID = selectedOption ? selectedOption.id : null;
        // 发送 update:areaCode 和 update:areaCodeID 事件
        this.$emit('update:areaCode', this.selectedAreaCode);
        this.$emit('update:areaCodeID', this.areaCodeID);
      })
    },
  },
  watch: {
    selectedAreaCode(newValue) {
      this.$emit('update:areaCode', newValue)
      // 更新 areaCodeID 的值
      const selectedOption = this.areaCodeOptions.find(
          item => item.code === newValue
      );
      this.areaCodeID = selectedOption ? selectedOption.id : null;
      this.$emit('update:areaCodeID', this.areaCodeID); // 新增发送 areaCodeID 事件
    }
  }
}
</script>

<style scoped>

</style>